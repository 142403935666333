
import { dispatchSubmitSetLanguage } from '@/store/main/actions';
import { readIsLoggedIn } from '@/store/main/getters';
import { Component, Vue, Prop } from 'vue-property-decorator';
// import {dispatchSetUserLanguage} from '@/store/main/actions';

@Component({
  components: {
  },
})
export default class I18nDropdown extends Vue {
  @Prop({default: ''}) public innerClass!: string;
  @Prop({default: false}) public mobile!: boolean;
  public locales = [
    { code: 'en', iso: 'en-US', file: 'en.json', title: 'English' },
    { code: 'ru', iso: 'ru-EU', file: 'ru.json', title: 'Русский' },
    { code: 'pt', iso: 'pt-BR', file: 'pt.json', title: 'Brasileiro' },
    // { code: 'es', iso: 'es-EU', file: 'es.json', title: 'Español' },
    // { code: 'tr', iso: 'tr-EU', file: 'tr.json', title: 'Türkçe' },
    // { code: 'zh', iso: 'zh-CN', file: 'zh.json', title: '中文' },
    // { code: 'uk', iso: 'ua-EU', file: 'ua.json', title: 'Українська' },
    // { code: 'de', iso: 'de-EU', file: 'de.json', title: 'Deutsch' },
    // { code: 'pl', iso: 'pl-EU', file: 'pl.json', title: 'Polski' },
  ];

  public async switchLocalePath(locale) {
    console.log("locale")
    console.log(locale)
    this.$root.$i18n.locale = locale;
    await dispatchSubmitSetLanguage(this.$store, {language: locale});
    if (this.isLoggedIn) {
      window.location.reload();
    }
  }
  get isLoggedIn() {
    return readIsLoggedIn(this.$store);
  }
}
